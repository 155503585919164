exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-posts-page-js": () => import("./../../../src/templates/BlogPostsPage.js" /* webpackChunkName: "component---src-templates-blog-posts-page-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/FrontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-pages-page-js": () => import("./../../../src/templates/PagesPage.js" /* webpackChunkName: "component---src-templates-pages-page-js" */),
  "component---src-templates-pages-policy-page-js": () => import("./../../../src/templates/PagesPolicyPage.js" /* webpackChunkName: "component---src-templates-pages-policy-page-js" */),
  "component---src-templates-wiki-page-js": () => import("./../../../src/templates/WikiPage.js" /* webpackChunkName: "component---src-templates-wiki-page-js" */)
}

